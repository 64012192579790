import { all, put, select, takeEvery } from "redux-saga/effects";
import { ChaptersAPIService } from "../../services/chapters/chapters";
import { doApiCall } from "../helper";
import { CHAPTER_ACTIONS } from "./action";
import { TOPIC_ACTIONS } from "../topics/action";

const chapterService = new ChaptersAPIService();

export function* GET_CHAPTERS({ payload }) {
  const response = yield doApiCall(
    chapterService.getChapters,
    payload,
    CHAPTER_ACTIONS.SET_STATE
  );

  if (response.success) {
    const {
      data,
      pagination,
    } = response;

    yield put({
      type: CHAPTER_ACTIONS.SET_STATE,
      payload: {
        data,
        pagination,
      },
    });

    let {user:{isValid}} = yield select((state) => state.authReducer);
    
    if(!isValid){
      put({
        type: TOPIC_ACTIONS.GET_FREE_TOPICS,
        payload: {
          query: {
            perPage: 100000
          }
        }
      })
    }

    // yield all([...data.map((item)=> item.id)].map((chapterId) => ))
  }
}

export function* GET_CHAPTER_BY_ID({ payload }) {
  const response = yield doApiCall(
    chapterService.getChapterById,
    payload,
    CHAPTER_ACTIONS.SET_STATE
  );
  if (response.success) {
    const { data } = response;
    yield put({
      type: CHAPTER_ACTIONS.SET_STATE,
      payload: {
        chapterData: data,
      },
    });
  }
}

export function* chapterSaga() {
  yield all([
    takeEvery(CHAPTER_ACTIONS.GET_CHAPTERS, GET_CHAPTERS),
    takeEvery(CHAPTER_ACTIONS.GET_CHAPTER_BY_ID, GET_CHAPTER_BY_ID),
  ]);
}
