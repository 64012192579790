import { Redirect, useLocation } from "react-router-dom";
import { Layout, Affix } from "antd";
import { useSelector } from "react-redux";

import Sidenav from "./Sidenav";
import Header from "./Header";
import Footer from "./Footer";

const { Header: AntHeader, Content, Sider } = Layout;

function Main({ children }) {
  let { pathname } = useLocation();

  const { isAuthenticated, user } = useSelector((reducers) => {
    return reducers.authReducer;
  });

  if (!isAuthenticated) {
    <Redirect to={"/auth"} />;
  }

  return (
    <Layout
      className={`layout-dashboard ${
        pathname === "profile" ? "layout-profile" : ""
      }`}
    >
      <Sider
        breakpoint="lg"
        trigger={null}
        width={250}
        theme="light"
        className={"sider-primary ant-layout-sider-primary active-route"}
      >
        <Sidenav />
      </Sider>
      <Layout>
        <Affix>
          <AntHeader className={"ant-header-fixed"}>
            <Header name={pathname} subName={pathname} user={user} />
          </AntHeader>
        </Affix>
        <Content className="content-ant">{children}</Content>
        <Footer />
      </Layout>
    </Layout>
  );
}

export default Main;
