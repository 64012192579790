import { Switch, Route, Redirect } from "react-router-dom";

import SignIn from "./pages/SignIn";
import Home from "./pages/Home";
import Topics from "./pages/Topics";
import NotFound from "./pages/NotFound";

import Main from "./components/layout/Main";

import "antd/dist/antd.css";
import "./assets/styles/main.css";
import "./assets/styles/responsive.css";

function App() {
  return (
    <div className="App">
      <Switch>
        <Route path="/auth" exact component={SignIn} />
        <Main>
          <Switch>
            <Route exact path="/course-content" component={Home} />
            <Route exact path="/:id/topics" component={Topics} />
            <Redirect from="*" to="/notfound" component={NotFound} />
          </Switch>
        </Main>
      </Switch>
    </div>
  );
}

export default App;
