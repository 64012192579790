import React from "react";
import { Layout, Button, Row, Col, Typography, Form, Input } from "antd";
import { useDispatch, useSelector } from "react-redux";

import logo from "../assets/images/logo.png";

import { AUTH_ACTIONS } from "../redux/auth/actions";

const { Title } = Typography;
const { Content } = Layout;

function SignIn() {
  const dispatch = useDispatch();

  const { loading } = useSelector((reducers) => {
    return reducers.authReducer;
  });

  const onFinish = ({ userName, password }) => {
    dispatch({
      type: AUTH_ACTIONS.LOGIN,
      payload: {
        body: {
          email: userName,
          password,
        },
      },
    });
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <>
      <Layout className="layout-default layout-signin">
        <Content className="signin">
          <Row
            gutter={[24, 0]}
            justify={"center"}
            align={"middle"}
            style={{ height: "calc(100vh - 40px)" }}
          >
            <Col
              xs={24}
              lg={10}
              md={10}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              order={{ xs: 2, md: 1, lg: 1 }}
            >
              <img src={logo} alt="logo" />
            </Col>
            <Col
              xs={24}
              lg={10}
              md={10}
              style={{
                textAlign: "left",
                paddingLeft: "20px",
                paddingRight: "20px",
              }}
              order={{ xs: 1, md: 2, lg: 2 }}
            >
              <Title className="mb-15">Eduthum WebVR</Title>
              <Title className="font-regular text-muted" level={5}>
                Login with your credentials to begin.
              </Title>
              <Form
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                layout="vertical"
                className="row-col"
              >
                <Form.Item
                  className="username"
                  label="Email"
                  name="userName"
                  rules={[
                    {
                      required: true,
                      message: "Please input your email!",
                    },
                  ]}
                >
                  <Input placeholder="Email" />
                </Form.Item>

                <Form.Item
                  className="userName"
                  label="Password"
                  name="password"
                  rules={[
                    {
                      required: true,
                      message: "Please input your password!",
                    },
                  ]}
                >
                  <Input.Password placeholder="Password" />
                </Form.Item>

                <Form.Item>
                  <Button
                    type="primary"
                    htmlType="submit"
                    style={{ width: "100%" }}
                    loading={loading}
                  >
                    SIGN IN
                  </Button>
                </Form.Item>
              </Form>
            </Col>
          </Row>
        </Content>
      </Layout>
    </>
  );
}

export default SignIn;
