import { Card, Col, Divider, Row, Select, Spin, Typography } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { SUBJECT_ACTIONS } from "../redux/subjects/action";
import { CHAPTER_ACTIONS } from "../redux/chapters/action";
import { history } from "../store";

const { Meta } = Card;

function Home() {
  const { Title } = Typography;
  const dispatch = useDispatch();
  const [selected, setSelected] = useState(null);

  const antIcon = <LoadingOutlined style={{ fontSize: 48 }} spin />;
  const topicPath = '/:id/topics'

  const load = () => {
    dispatch({
      type: SUBJECT_ACTIONS.GET_SUBJECTS,
      payload: {
        query: {
          perPage: 1000000,
        },
      },
    });
  };

  useEffect(() => {
    load();
  }, []);

  const { subjectReducer, chapterReducer, topicReducer } = useSelector(
    (s) => s
  );

  const formatDataForSelect = () => {
    return subjectReducer.data.map((item) => {
      return {
        label: item.name,
        value: item.id,
      };
    });
  };

  const handleSelectedChange = (value) => {
    setSelected(value);
  };

  useEffect(() => {
    dispatch({
      type: CHAPTER_ACTIONS.GET_CHAPTERS,
      payload: {
        params: {
          subjectId: selected,
        },
        query: {
          perPage: 1000000,
        },
      },
    });
  }, [selected]);

  const handlechapterRedirect = (chapterId) => {
    history.push(topicPath.replace(":id", chapterId))
  }

  return (
    <>
      <div className="layout-content">
        <Row style={{ paddingBottom: "10px" }}>
          <Select
            options={formatDataForSelect()}
            placeholder={"Select Subject"}
            onChange={handleSelectedChange}
            style={{ width: "300px" }}
          />
        </Row>
        {subjectReducer.loading ||
        chapterReducer.loading ||
        topicReducer.loading ? (
          <Spin
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
            }}
            indicator={antIcon}
          />
        ) : (
          <Row className="mb-24">
            {selected && topicReducer.isFreeTopics ? (
              <>
                <Col xs={24}>
                  <Title
                    style={{ marginBottom: "0px", marginTop: "20px" }}
                    level={4}
                  >
                    Free Topics
                  </Title>
                  <Divider style={{ margin: "5px" }} />
                </Col>
                {topicReducer.data.map((topic, _k) => (
                  <Col xs={8} style={{ padding: "10px 10px 0px 0px" }}>
                    <Card
                      key={_k}
                      hoverable
                      cover={
                        <img alt={topic.name} src={topic.image[0].preview} />
                      }
                    >
                      <Meta title={topic.name} />
                    </Card>
                  </Col>
                ))}
              </>
            ) : (
              <>
                {chapterReducer.data.map((item, _k) => {
                  return (
                      <Col xs={6} key={_k}>
                        <Card
                          hoverable
                          cover={
                            <img
                              alt={item.name}
                              src={item.image[0].preview}
                            />
                          }
                          style={{
                            marginTop: '20px'
                          }}
                          onClick={()=>{handlechapterRedirect(item.id)}}
                        >
                          <Meta title={item.name} />
                        </Card>
                      </Col>
                  );
                })}
              </>
            )}
          </Row>
        )}
      </div>
    </>
  );
}

export default Home;
