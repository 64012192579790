import { connectRouter } from "connected-react-router";
import { combineReducers } from "redux";
import { authReducer } from "./auth/reducers";
import { userReducer } from "./user/reducer";
import { subjectReducer } from "./subjects/reducer";
import { chapterReducer } from "./chapters/reducer";
import { topicReducer } from "./topics/reducer";

const rootReducer = (history) =>
  combineReducers({
    router: connectRouter(history),
    authReducer,
    userReducer,
    subjectReducer,
    chapterReducer,
    topicReducer
  });

export default rootReducer;
