import { call, put } from "redux-saga/effects";
import { notification } from "antd";

import { history } from "../store";

export function* doApiCall(service, payload, ACTION_SET_STATE) {
  yield showLoading(ACTION_SET_STATE, true);
  yield put({ type: ACTION_SET_STATE, payload: { formError: {}, success: false } });
  try {
    yield showLoading(ACTION_SET_STATE, false);
    const response = yield call(service, payload);
    if (response.status === 204) {
      return { success: true };
    }
    return {success: true, ...response.data};
  } catch (e) {
    console.log(e)
    yield showLoading(ACTION_SET_STATE, false);
    const { response } = e;
    if (response) {
      const { status, data } = response;
      if (status === 422) {
        yield put({
          type: ACTION_SET_STATE,
          payload: { formError: data.errors },
        });
      }
      if (status === 403) {
        yield history.push("/auth");
      }
    } else {
      notification.error({
        message: "Server Error",
        description: "Error reading response from server",
      });
    }
  }
  return { success: false };
}

export function* showLoading(action, loading) {
  yield put({ type: action, payload: { loading } });
}
